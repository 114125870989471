<template>
    <template v-if="props.isAuthenticated">
        <li
            v-if="(!props.canManageGymProfile && !props.isGuestUser)"
            class="ocn-list-item"
        >
            <a
                v-if="props.canAccessMemberMenu"
                class="ocn-list-link"
                :class="{'js-active-item active-item': !isKeyEmpty(props.active?.['/dashboard'])}"
                :href="'/' + props.currentUserLocale + '/dashboard'"
                data-ga-category="Navigation Menu"
                data-ga-action="My SYNC Home"
                :data-ga-label="props.gaLabel"
            >
                {{ props.t.syncHomeText }}
            </a>
            <a
                v-else
                class="ocn-list-link"
                :href="'/' + props.currentUserLocale + '/dashboard'"
                data-ga-category="Navigation Menu"
                data-ga-action="My Dashboard"
                :data-ga-label="props.gaLabel"
            >
                {{ props.t.myDashboardText }}
            </a>
            <a
                v-if="props.hasPendingFirstTraining"
                class="ocn-list-link"
                :class="{'js-active-item active-item': props.active?.['MemberTools']?.['/training/history']}"
                :href="'/' + props.currentUserLocale + '/training/history'"
                data-ga-category="Navigation Menu"
                data-ga-action="Training History"
                :data-ga-label="props.gaLabel"
            >
                {{ props.t.myTrainingsText }}
            </a>
        </li>
        <li class="ocn-list-item">
            <a
                class="more-below ocn-list-link"
                :class="{
                    'js-active-item inactive-item': !isKeyEmpty(props.active?.['Tools']) || openTools || openClassManagement || openNestedSubmenu,
                }"
                href="#"
                :title="props.t.memberToolsText"
            >
                {{ props.t.memberToolsText }}
            </a>
            <ul
                class="ocn-subnav"
                :style="(!isKeyEmpty(props.active?.['Tools']) || openTools || openClassManagement || openNestedSubmenu) ? 'display: block;' : ''"
            >
                <li
                    v-if="(props.isBenefitMembershipStatusCardEnabled && props.userHasBenefitMembershipStatusCard)"
                    class="ocn-list-item"
                >
                    <a
                        class="ocn-list-link"
                        :class="{'js-active-item active-item': props.active?.['/membership/status_card' + props.membershipTypeSlug.toLowerCase()]}"
                        :href="'/' + props.currentUserLocale + '/membership/status_card/' + props.membershipTypeSlug.toLowerCase()"
                        :title="props.t.statusCardText"
                    >
                        {{ props.t.statusCardText }}
                    </a>
                </li>
                <li
                    v-if="props.canAccessDcd"
                    class="ocn-list-item"
                >
                    <a
                        id="hamburger-link-learn-zin-media"
                        :href="'/' + props.currentUserLocale + '/now'"
                        class="ocn-list-link"
                        :class="{'js-active-item active-item': !isKeyEmpty(props.active?.['Learn']?.['/now'])}"
                        data-ga-category="Navigation Menu"
                        data-ga-action="SYNC Now"
                        :data-ga-label="props.gaLabel"
                    >
                        {{ props.t.strongNowText }}
                    </a>
                </li>
                <li
                    v-if="props.hasPreMembershipLinksOnly"
                >
                    <span class="ocn-list-item__no-access ocn-list-link">
                        {{ props.t.classManagement }}
                    </span>
                </li>
                <li
                    v-else-if="props.canAccessMemberMenu && props.classManagementEnabled"
                    class="ocn-list-item"
                >
                    <a
                        :href="'/' + props.currentUserLocale + '/member/class_management'"
                        class="ocn-list-link more-below with-new-feature-badge"
                        :class="{
                            'js-active-item inactive-item': !isKeyEmpty(props.active?.['ClassManagement']) || openClassManagement,
                            'pre-selected-active': !isKeyEmpty(props.active?.['ClassManagement']) || openClassManagement
                        }"
                    >
                        <span class="new-feature-item">
                            {{ props.t.classManagement }}
                        </span>
                        <span class="new-feature-tag-wrap">
                            <Tag
                                variety="small featured"
                                :show-cancel="false"
                                class="new-feature-tag"
                            >
                                {{ props.t.newFeature }}
                            </Tag>
                        </span>
                    </a>
                    <ul
                        class="ocn-subnav"
                        :style="!isKeyEmpty(props.active?.['ClassManagement']) ||
                            openClassManagement ? 'display: block;': ''"
                    >
                        <li class="ocn-list-item">
                            <a
                                :href="'/' + props.currentUserLocale + '/member/class_management'"
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': props.active?.['ClassManagement']?.['/member/class_management']}"
                                data-ga-category="Class Management"
                                data-ga-action="My Classes"
                                :data-ga-label="props.gaLabel"
                            >
                                {{ props.t.myClasses }}
                            </a>
                        </li>
                        <li class="ocn-list-item">
                            <a
                                :href="'/' + props.currentUserLocale + '/member/instructor_page/view'"
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': props.active?.['ClassManagement']?.['/member/instructor_page/view']}"
                                data-ga-category="Class Management"
                                data-ga-action="Instructor Page"
                                :data-ga-label="props.gaLabel"
                            >
                                {{ props.t.instructorPageText }}
                            </a>
                        </li>
                        <li class="ocn-list-item">
                            <a
                                :href="'/' + props.currentUserLocale + '/member/packages'"
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': props.active?.['ClassManagement']?.['/member/packages']}"
                                data-ga-category="Class Management"
                                data-ga-action="Class Packs"
                                :data-ga-label="props.gaLabel"
                            >
                                {{ props.t.classPacks }}
                            </a>
                        </li>
                        <li class="ocn-list-item">
                            <a
                                :href="'/' + props.currentUserLocale + '/member/class_management/students'"
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': props.active?.['ClassManagement']?.['/member/class_management/students']}"
                                data-ga-category="Class Management"
                                data-ga-action="Students"
                                :data-ga-label="props.gaLabel"
                            >
                                {{ props.t.students }}
                            </a>
                        </li>
                        <li class="ocn-list-item">
                            <a
                                :href="'/' + props.currentUserLocale + '/member/class_management/settings'"
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': props.active?.['ClassManagement']?.['/member/class_management/settings']}"
                                data-ga-category="Class Management"
                                data-ga-action="Settings"
                                :data-ga-label="props.gaLabel"
                            >
                                {{ props.t.settings }}
                            </a>
                        </li>
                    </ul>
                </li>
                <li
                    v-if="props.canAccessMemberMenu && !props.classManagementEnabled"
                    class="ocn-list-item"
                >
                    <a
                        :href="props.manageClassesAction"
                        class="ocn-list-link"
                        :class="{'js-active-item active-item': props.manageClassesActive}"
                        data-ga-category="Navigation Menu"
                        data-ga-action="Tools_My Classes"
                        :data-ga-label="props.gaLabel"
                    >
                        {{ props.t.manageClassesText }}
                    </a>
                </li>
                <li v-if="hasPreMembershipLinksOnly">
                    <span
                        v-if="props.hasPreMembershipLinksOnly"
                        class="ocn-list-item__no-access ocn-list-link"
                    >
                        {{ props.t.postYourLivestreamText }}
                    </span>
                </li>
                <li
                    v-if="props.memberProfileEnabled && props.userHasBenefitInstructorProfile"
                    class="ocn-list-item"
                >
                    <span
                        v-if="props.hasPreMembershipLinksOnly"
                        class="ocn-list-item__no-access ocn-list-link"
                    >
                        {{ props.t.instructorPageText }}
                    </span>
                    <a
                        v-else
                        :href="'/' + props.currentUserLocale + '/member/profile'"
                        class="ocn-list-link"
                        :class="{'js-active-item active-item': !isKeyEmpty(props.active?.['MemberTools']?.['/instructor/' + props.profileUrlParams])}"
                        data-ga-category="Navigation Menu"
                        data-ga-action="Member Tools_My Profile"
                        :data-ga-label="props.gaLabel"
                    >
                        {{ props.t.instructorPageText }}
                    </a>
                </li>
                <li
                    v-if="props.isAuthenticated"
                    class="ocn-list-item"
                >
                    <a
                        :href="'/' + props.currentUserLocale + '/training/history'"
                        class="ocn-list-link"
                        :class="{'js-active-item active-item': !isKeyEmpty(props.active?.['MemberTools']?.['/training/history'])}"
                        data-ga-category="Navigation Menu"
                        data-ga-action="Member Tools_My Trainings"
                        :data-ga-label="props.gaLabel"
                    >
                        {{ props.t.userMenuMyTrainingsText }}
                    </a>
                </li>
                <li
                    class="ocn-list-item"
                >
                    <a
                        class="more-below ocn-list-link"
                        :class="{
                            'js-active-item inactive-item': !isKeyEmpty(props.active?.['Teach']),
                            'pre-selected-active': !isKeyEmpty(props.active?.['Teach'])
                        }"
                    >
                        {{ props.t.teachingPlusPromotionText }}
                    </a>
                    <ul
                        class="ocn-subnav subnav-nested"
                        :style="!isKeyEmpty(props.active?.['Teach']) ? 'display: block;': ''"
                    >
                        <li
                            v-if="props.canAccessMemberMenu && props.isSyncgoAppLinksEnabled"
                            class="ocn-list-item"
                        >
                            <a
                                class="ocn-list-link"
                                :href="'/' + props.currentUserLocale + '/syncgo'"
                                :class="{'js-active-item active-item': props.active?.['Teach']?.['/syncgo']}"
                                data-ga-category="Navigation Menu"
                                data-ga-action="SYNC_Go_App"
                            >
                                {{ props.t.syncGoAppText }}
                            </a>
                        </li>
                        <li
                            v-if="props.canAccessMemberMenu && props.isClassbuzzAppLinksEnabled"
                            class="ocn-list-item"
                        >
                            <span
                                v-if="props.hasPreMembershipLinksOnly"
                                class="ocn-list-item__no-access ocn-list-link"
                            >
                                {{ props.t.classBuzzAppText }}
                            </span>
                            <a
                                v-else
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': props.active?.['Teach']?.['/classbuzz']}"
                                :href="'/' + props.currentUserLocale + '/classbuzz'"
                                data-ga-category="Navigation Menu"
                                data-ga-action="Class_Buzz_App"
                            >
                                {{ props.t.classBuzzAppText }}
                            </a>
                        </li>
                        <li
                            v-if="props.canAccessMemberMenu"
                            class="ocn-list-item"
                        >
                            <span
                                v-if="props.hasPreMembershipLinksOnly"
                                class="ocn-list-item__no-access ocn-list-link"
                            >
                                {{ props.t.marketingMaterialsText }}
                            </span>
                            <a
                                v-else
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': props.active?.['Teach']?.['/marketing_materials']}"
                                target="_blank"
                                rel="noopener"
                                :href="'/' + props.currentUserLocale + '/marketing_materials'"
                                data-ga-category="Navigation Menu"
                                data-ga-action="Marketing Materials"
                                :data-ga-label="props.gaLabel"
                                @click="() => track('MM: Downloads', {})"
                            >
                                {{ props.t.marketingMaterialsText }}
                            </a>
                        </li>
                    </ul>
                </li>
                <li
                    class="ocn-list-item"
                >
                    <a
                        class="more-below ocn-list-link"
                        :class="{
                            'js-active-item inactive-item': !isKeyEmpty(props.active?.['Learn']),
                            'pre-selected-active': !isKeyEmpty(props.active?.['Learn'])
                        }"
                    >
                        {{ props.t.trainingsPlusEducationText }}
                    </a>
                    <ul
                        class="ocn-subnav"
                        :style="!isKeyEmpty(props.active?.['Learn']) ? 'display: block;': ''"
                    >
                        <li class="ocn-list-item">
                            <span
                                v-if="props.hasPreMembershipLinksOnly && props.restrictedInstructorTraining"
                                class="ocn-list-item__no-access ocn-list-link"
                            >
                                {{ props.t.instructorTrainingsText }}
                            </span>
                            <a
                                v-else
                                :href="'/' + props.currentUserLocale + '/training'"
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': props.active?.['Learn']?.['/training']}"
                                data-ga-category="Navigation Menu"
                                data-ga-action="Trainings Education_Instructor Trainings"
                                :data-ga-label="props.gaLabel"
                            >
                                {{ props.t.instructorTrainingsText }}
                            </a>
                        </li>
                        <li
                            v-if="props.isMembershipCirclInstructorNavigationEnabled"
                            class="ocn-list-item"
                        >
                            <a
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': props.active?.['Learn']?.['/circl-mobility-instructor']}"
                                :href="'/' + props.currentUserLocale + '/circl-mobility-instructor'"
                                data-ga-category="ZUMBA Home"
                                :data-ga-action="`${
                                    $props.isMembershipTypeZumba ? 'CIRCL_Mobility_ZIN' : 'CIRCL_Mobility_SYNC'}`"
                            >
                                <span
                                    v-if="props.isMembershipCirclInstructorNavigationNewEnabled && !props.isNoomCertificationEnabled"
                                    class="new-feature-item"
                                >
                                    CIRCL Mobility™
                                </span>
                                <span
                                    v-if="props.isMembershipCirclInstructorNavigationNewEnabled && !props.isNoomCertificationEnabled"
                                    class="new-feature-tag-wrap"
                                >
                                    <Tag
                                        variety="small featured"
                                        :show-cancel="false"
                                        class="new-feature-tag"
                                    >
                                        {{ props.t.newFeature }}
                                    </Tag>
                                </span>
                                <span
                                    v-if="!props.isMembershipCirclInstructorNavigationNewEnabled || props.isNoomCertificationEnabled"
                                >
                                    CIRCL Mobility™
                                </span>
                            </a>
                        </li>
                        <li
                            v-if="props.isAuthenticated && props.isZjRefactorDashboardStrongEnabled && props.userHasBenefitZinJammerSessions"
                            class="ocn-list-item"
                        >
                            <a
                                :href="'/' + props.currentUserLocale + '/sync_labs'"
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': props.active?.['Learn']?.['/sync_labs']}"
                                data-ga-category="Navigation Menu"
                                data-ga-action="Instructors_SYNC Labs"
                                :data-ga-label="props.gaLabel"
                            >
                                {{ props.t.syncLabsText }}
                            </a>
                        </li>
                        <li
                            v-if="props.canAccessMemberMenu && !props.hideJapaneseMenu"
                            class="ocn-list-item"
                        >
                            <span
                                v-if="props.hasPreMembershipLinksOnly"
                                class="ocn-list-item__no-access ocn-list-link"
                            >
                                {{ props.t.eLearningText }}
                            </span>
                            <a
                                v-else
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': props.active?.['Learn']?.['/member/elearning']}"
                                :href="'/' + props.currentUserLocale + '/member/elearning'"
                                data-ga-category="Navigation Menu"
                                data-ga-action="E-Learning"
                                :data-ga-label="props.gaLabel"
                            >
                                {{ props.t.eLearningText }}
                            </a>
                        </li>
                        <li
                            v-if="showNoom"
                            class="ocn-list-item"
                        >
                            <span
                                v-if="(props.isNoomCertificationEnabled && props.hasPreMembershipLinksOnly)"
                                class="ocn-list-item__no-access ocn-list-link"
                            >
                                {{ props.t.noomCoachingText }}
                            </span>
                            <slot
                                v-else-if="props.isNoomCertificationEnabled"
                                name="noom-link"
                            />
                        </li>
                    </ul>
                </li>
                <li
                    class="ocn-list-item"
                >
                    <a
                        class="more-below ocn-list-link"
                        :class="{
                            'js-active-item inactive-item': !isKeyEmpty(props.active?.['Earn']),
                            'pre-selected-active': !isKeyEmpty(props.active?.['Earn'])
                        }"
                    >
                        {{ props.t.earningsText }}
                    </a>
                    <ul
                        class="ocn-subnav"
                        :style="!isKeyEmpty(props.active?.['Earn']) ? 'display: block;': ''"
                    >
                        <li class="ocn-list-item">
                            <!-- eslint-disable vue/no-v-html -->
                            <span
                                v-if="props.hasPreMembershipLinksOnly && props.restrictedReferFriend"
                                class="ocn-list-item__no-access ocn-list-link"
                                v-html="props.t.referFriendText"
                            />
                            <span v-else>
                                <a
                                    v-if="props.newReferralProgram && props.showShareAndEarn"
                                    :href="'/' + props.currentUserLocale + '/training/share_and_earn'"
                                    class="ocn-list-link"
                                    :class="{'js-active-item active-item': props.active?.['Earn']?.['/training/share_and_earn']}"
                                    data-ga-category="Navigation Menu"
                                    data-ga-action="Refer_A_Friend"
                                    :data-ga-label="props.gaLabel"
                                    v-html="props.t.referFriendText"
                                />
                                <a
                                    v-if="!props.newReferralProgram"
                                    :href="'/' + props.currentUserLocale + '/training/refer'"
                                    class="ocn-list-link"
                                    :class="{'js-active-item active-item': props.active?.['Earn']?.['/training/refer']}"
                                    data-ga-category="Navigation Menu"
                                    data-ga-action="Refer_A_Friend"
                                    :data-ga-label="props.gaLabel"
                                    v-html="props.t.referFriendText"
                                />
                            </span>
                            <!-- eslint-enable -->
                        </li>
                        <li
                            v-if="!props.hasPreMembershipLinksOnly && (
                                props.canAccessMemberMenu &&
                                props.isTippingLandingLinkLeftMenuEnabled &&
                                !props.hasPreMembershipLinksOnly
                            )"
                            class="ocn-list-item"
                        >
                            <a
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': props.active?.['Earn']?.['/member/tipping']}"
                                target="_blank"
                                rel="noopener"
                                :href="'/' + props.currentUserLocale + '/member/tipping'"
                                data-ga-category="Member Home"
                                data-ga-action="Tipping"
                                :data-ga-label="props.gaLabel"
                            >
                                {{ props.t.tippingText }}
                            </a>
                        </li>
                        <li
                            v-if="props.canAccessMemberMenu"
                            class="ocn-list-item"
                        >
                            <span
                                v-if="props.hasPreMembershipLinksOnly"
                                class="ocn-list-item__no-access ocn-list-link"
                            >
                                {{ props.t.discountsText }}
                            </span>
                            <a
                                v-else
                                :href="'/' + props.currentUserLocale + '/member/earn-discount'"
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': props.active?.['Earn']?.['/member/earn-discount']}"
                            >
                                {{ props.t.discountsText }}
                            </a>
                        </li>
                        <li class="ocn-list-item">
                            <span
                                v-if="props.hasPreMembershipLinksOnly && props.restrictedLeadershipOpportunities"
                                class="ocn-list-item__no-access ocn-list-link"
                            >
                                {{ props.t.leadershipOpportunitiesText }}
                            </span>
                            <!-- eslint-disable vue/no-v-html -->
                            <a
                                v-else
                                :href="'/' + props.currentUserLocale + '/sync-leadership'"
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': props.active?.['Earn']?.['/sync-leadership']}"
                                data-ga-category="Navigation Menu"
                                data-ga-action="Leadership_Opportunities"
                                :data-ga-label="props.gaLabel"
                                v-html="props.t.leadershipOpportunitiesText"
                            />
                            <!-- eslint-enable -->
                        </li>
                    </ul>
                </li>
                <li
                    class="ocn-list-item"
                >
                    <a
                        class="more-below ocn-list-link"
                        :class="{
                            'js-active-item inactive-item': !isKeyEmpty(props.active?.['Connect']),
                            'pre-selected-active': !isKeyEmpty(props.active?.['Connect'])
                        }"
                        href="#"
                    >
                        {{ props.t.communityText }}
                    </a>
                    <ul
                        class="ocn-subnav"
                        :style="!isKeyEmpty(props.active?.['Connect']) ? 'display: block;': ''"
                    >
                        <li
                            v-if="hasPreMembershipLinksOnly || (
                                props.canAccessMemberMenu && props.userHasBenefitStrongForumAccess)"
                            class="ocn-list-item"
                        >
                            <span
                                v-if="props.hasPreMembershipLinksOnly"
                                class="ocn-list-item__no-access ocn-list-link"
                            >
                                {{ props.t.forumText }}
                            </span>
                            <a
                                v-else
                                :href="communityLink"
                                class="ocn-list-link"
                                data-ga-category="Navigation Menu"
                                data-ga-action="Forum"
                                :data-ga-label="props.gaLabel"
                            >
                                {{ props.t.forumText }}
                            </a>
                        </li>
                        <li
                            v-if="props.canAccessMemberMenu"
                            class="ocn-list-item"
                        >
                            <a
                                :href="'/' + props.currentUserLocale + '/contact'"
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': props.active?.['Connect']?.['/contact']}"
                                data-ga-category="Navigation Menu"
                                data-ga-action="SYNC Support"
                                :data-ga-label="props.gaLabel"
                            >
                                {{ props.t.syncSupportText }}
                            </a>
                        </li>
                        <li
                            v-if="props.canAccessMemberMenu"
                            class="ocn-list-item"
                        >
                            <span
                                v-if="props.hasPreMembershipLinksOnly"
                                class="ocn-list-item__no-access ocn-list-link"
                            >
                                {{ props.t.communityChampionsText }}
                            </span>
                            <a
                                v-else
                                :href="'/' + props.currentUserLocale + '/sync-champions'"
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': props.active?.['Connect']?.['/sync-champions']}"
                                data-ga-category="Strong Home"
                                data-ga-action="Sync Champions"
                                :data-ga-label="props.gaLabel"
                            >
                                {{ props.t.communityChampionsText }}
                            </a>
                        </li>
                    </ul>
                </li>
                <li
                    v-if="props.canAccessEducationSpecialistMenu"
                    class="ocn-list-item"
                >
                    <a
                        class="more-below ocn-list-link"
                        :class="{'js-active-item inactive-item': !isKeyEmpty(props.active?.['ZES Support'])}"
                        :href="'/' + props.currentUserLocale + '/support'"
                    >
                        {{ props.t.masterTrainerLinksText }}
                    </a>
                    <ul
                        class="ocn-subnav"
                        :style="isKeyEmpty(props.active?.['ZES Support']) ? 'display: block;' : ''"
                    >
                        <li class="ocn-list-item">
                            <a
                                :href="'/' + props.currentUserLocale + '/es/training_status'"
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': !isKeyEmpty(props.active?.['ZES Support']?.['/zes/training_status'])}"
                                data-ga-category="Navigation Menu"
                                data-ga-action="ZES Support_Instructor Training Status"
                                :data-ga-label="props.gaLabel"
                            >
                                {{ props.t.instructorTrainingStatusText }}
                            </a>
                        </li>
                        <li
                            v-if="props.isDgfPublicEnabled"
                            class="ocn-list-item"
                        >
                            <a
                                :href="'/' + props.currentUserLocale + '/es/training_status/attendance'"
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': !isKeyEmpty(props.active?.['ZES Support']?.['/zes/training_status/attendance'])}"
                                :data-ga-category="props.membershipTypeSlug + ' Home'"
                                data-ga-action="ZES Support_Instructor Training Status"
                                :data-ga-label="props.gaLabel"
                            >
                                {{ props.t.trainingAttendanceText }}
                            </a>
                        </li>
                        <li
                            v-if="props.canManageTrainingLocations"
                            class="ocn-list-item"
                        >
                            <a
                                :href="'/' + props.currentUserLocale + '/es/location/training_index'"
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': !isKeyEmpty(props.active?.['ZES Support']?.['/zes/location/training_index'])}"
                                data-ga-category="Navigation Menu"
                                data-ga-action="ZES Support_Training Location Management"
                                :data-ga-label="props.gaLabel"
                            >
                                {{ props.t.trainingLocationManagementText }}
                            </a>
                        </li>
                        <li
                            v-if="props.canRequestTrainings"
                            class="ocn-list-item"
                        >
                            <a
                                :href="'/' + props.currentUserLocale + '/es/training_request'"
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': !isKeyEmpty(props.active?.['ZES Support']?.['/zes/training_request'])}"
                                data-ga-category="Navigation Menu"
                                data-ga-action="ZES Support_Training Request Form"
                                :data-ga-label="props.gaLabel"
                            >
                                {{ props.t.trainingRequestFormText }}
                            </a>
                        </li>
                        <li class="ocn-list-item">
                            <a
                                :href="'/' + props.currentUserLocale + '/es/training_registration/add'"
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': !isKeyEmpty(props.active?.['ZES Support']?.['/zes/training_registration/add'])}"
                                data-ga-category="Navigation Menu"
                                data-ga-action="ZES Support_Training Registration"
                                :data-ga-label="props.gaLabel"
                            >
                                {{ props.t.trainingRegistrationText }}
                            </a>
                        </li>
                        <li
                            v-if="props.canAccessEducationSpecialistDocuments"
                            class="ocn-list-item"
                        >
                            <a
                                :href="'/' + props.currentUserLocale + '/es/zes_document'"
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': !isKeyEmpty(props.active?.['ZES Support']?.['/zes/zes_document'])}"
                                data-ga-category="Navigation Menu"
                                data-ga-action="ZES Support_ZES Docs"
                                :data-ga-label="props.gaLabel"
                            >
                                {{ props.t.masterTrainerDocsText }}
                            </a>
                        </li>
                        <li
                            v-if="props.canRequestTrainingMaterials"
                            class="ocn-list-item"
                        >
                            <a
                                :href="'/' + props.currentUserLocale + '/es/training_material'"
                                class="ocn-list-link"
                                :class="{'js-active-item active-item': !isKeyEmpty(props.active?.['ZES Support']?.['/zes/training_material'])}"
                                data-ga-category="Navigation Menu"
                                data-ga-action="ZES Support_Training Material Request"
                                :data-ga-label="props.gaLabel"
                            >
                                {{ props.t.trainingMaterialRequestText }}
                            </a>
                        </li>
                    </ul>
                </li>
                <li
                    v-if="props.canAccessMemberMenu && props.hasTechnicalSessionLink"
                    class="ocn-list-item"
                >
                    <a
                        :href="'/' + props.currentUserLocale + '/trainer/session/my'"
                        class="ocn-list-link"
                    >
                        {{ props.t.manageSyncLabsText }}
                    </a>
                </li>
            </ul>
        </li>
        <OnHold
            :t="props.t"
            :current-user-locale="props.currentUserLocale"
            :is-on-hold="props.isOnHold"
            :on-hold-end="props.onHoldEnd"
        />
        <li class="ocn-list-item">
            <a
                v-if="(props.canManageGymProfile && props.canAccessMemberMenu)"
                class="ocn-list-link"
                :class="{'js-active-item active-item': !isKeyEmpty(props.active?.['/profile'])}"
                :href="'/' + props.currentUserLocale + props.zinProfileAccountLink"
                data-ga-category="Navigation Menu"
                data-ga-action="Tools_Manage My Account"
                :data-ga-label="props.gaLabel"
            >
                {{ props.t.manageMyAccountText }}
            </a>
            <a
                v-else
                class="ocn-list-link"
                :class="{'js-active-item active-item': !isKeyEmpty(props.active?.['/profile'])}"
                :href="'/' + props.currentUserLocale + '/' + props.zinProfileAccountLink"
                data-ga-category="Navigation Menu"
                data-ga-action="Tools_My Account"
                :data-ga-label="props.gaLabel"
            >
                {{ props.t.myAccountText }}
            </a>
        </li>
        <li
            class="ocn-list-item"
        >
            <a
                v-if="props.userHasDelinquentMembership"
                class="ocn-list-link"
                :href="'/' + props.currentUserLocale + (props.isPrePaymentAccessEnabled ? '/membership/pay' : '/dashboard')"
                data-ga-category="Navigation Menu"
                data-ga-action="Update Payment"
                :data-ga-label="props.gaLabel"
            >
                {{ props.t.updatePaymentText }}
            </a>
        </li>
        <!-- student_dashboard -->
        <li
            v-if="props.canAccessDcd"
            class="ocn-list-item"
        >
            <!-- eslint-disable vue/no-v-html -->
            <a
                id="hamburger-link-student-dashboard"
                :href="'/' + props.currentUserLocale + '/student_dashboard'"
                class="ocn-list-link"
                :class="{'js-active-item active-item': props.active?.['/student_dashboard']}"
                data-ga-category="Navigation Menu"
                data-ga-action="{{studentDashboardText}}"
                v-html="studentDashboardText"
            />
            <!-- eslint-enable -->
        </li>
    </template>
    <Classes
        :active="props.active"
        :t="props.t"
        :current-user-locale="props.currentUserLocale"
        :ga-label="props.gaLabel"
        :online-class-base-url="props.onlineClassBaseUrl"
        :request-query-cat="requestQueryCat"
        :class-locator-enabled="props.classLocatorEnabled"
        :is-circl-url="props.isCirclUrl"
    />
    <Instructors
        :active="props.active"
        :t="props.t"
        :is-strong-virtual-header-menu-enabled="props.isStrongVirtualHeaderMenuEnabled"
        :current-user-locale="props.currentUserLocale"
        :ga-label="props.gaLabel"
    >
        <Sessions
            v-if="props.isZjRefactorNavigationStrongEnabled"
            :t="props.t"
            :is-authenticated="props.isAuthenticated"
            :has-pre-membership-links-only="props.hasPreMembershipLinksOnly"
            :active="props.active"
            :current-user-locale="props.currentUserLocale"
            :ga-label="props.gaLabel"
        />
    </Instructors>
    <Shop
        :t="props.t"
        :ga-label="props.gaLabel"
        :navigation-shop-url="props.navigationShopUrl"
        :is-strong-shop-subnav-enabled="props.isStrongShopSubnavEnabled"
    />
    <About
        v-if="props.isStrongResultsMenuEnabled"
        :t="props.t"
        :active="props.active"
        :current-user-locale="props.currentUserLocale"
        :ga-label="props.gaLabel"
        :is-job-board-form-enabled="props.isJobBoardFormEnabled"
        :is-gym-post-a-job-enabled="props.isGymPostAJobEnabled"
    />
    <Benefits
        :active="props.active"
        :t="props.t"
        :current-user-locale="props.currentUserLocale"
        :ga-label="props.gaLabel"
        :should-show-join-link="props.shouldShowJoinLink"
    />
</template>
<script setup lang="ts">
import { track } from '@ts/Util/analytics';
import { computed } from 'vue';
import OnHold from '../Zumba/OnHold.vue';
import Classes from './Classes.vue';
import Shop from './Shop.vue';
import About from './About.vue';
import Instructors from './Instructors.vue';
import Sessions from './Sessions.vue';
import Benefits from './Benefits.vue';
import Tag from '@components/Tags/Tag.vue'

const props = defineProps({
    active: { type: Object, default: () => {} },
    t: {
        type: Object,
        default: () => ({
            aboutText: 'About',
            aboutTheClassText: 'About the Class',
            accountOnHoldText: 'Account on Hold',
            becomeAnInstructorText: 'Become an Instructor',
            circlNewTagText: 'New',
            classesText: 'Classes',
            discountsText: 'Discounts',
            eLearningText: 'E-Learning',
            findAClassText: 'Find a Class',
            findATrainingText: 'Find a Training',
            findAVirtualClassText: 'Find a Virtual Class',
            findAnInPersonClassText: 'Find An In-Person Class',
            findAnInstructorText: 'Find an Instructor',
            forGymsText: 'For Gyms',
            forumText: 'Forum',
            globalShopText: 'Shop Clothing',
            instructorPageText: 'Instructor Page',
            instructorTrainingStatusText: 'Instructor Training Status',
            instructorsText: 'Instructors',
            manageMyAccountText: 'Manage My Account',
            manageSyncLabsText: 'Manage SYNC Labs',
            marketingMaterialsText: 'Marketing Materials',
            memberBenefitsText: 'Member Benefits',
            memberToolsText: 'Member Tools',
            myAccountText: 'My Account',
            myDashboardText: 'My Dashboard',
            syncHomeText: 'SYNC Home',
            myTrainingsText: 'My Trainings',
            onHoldEndStatusText: 'Your account is currently on hold',
            ourStoryText: 'Our Story',
            postYourLivestreamText: 'Post Your Livestream',
            resultsText: 'Results',
            sessionsSyncLabsText: 'SYNC Labs',
            shopText: 'Shop Clothing',
            strongForGymsText: 'STRONG for Gyms',
            strongNowText: 'STRONG Now',
            syncGoAppText: 'SYNC Go App',
            classBuzzAppText: 'Class Buzz App',
            syncLabsText: 'SYNC Labs',
            syncSupportText: 'SYNC Support',
            tippingText: 'Tipping',
            trainingAttendanceText: 'Training Attendance',
            trainingLocationManagementText: 'Training Location Management',
            trainingMaterialRequestText: 'Training Material Request',
            trainingRegistrationText: 'Training Registration',
            trainingRequestFormText: 'Training Request Form',
            updateOnHoldDateText: 'Update on Hold Date',
            updatePaymentText: 'Update Payment',
            userMenuMyTrainingsText: 'My Trainings',
            viewAllTrainingsText: 'View All Trainings',
            virtualTrainingsText: 'Virtual Trainings',
            masterTrainerLinksText: 'Master Trainer Links',
            masterTrainerDocsText: 'Master Trainer Docs',
            classManagement: 'Class Management',
            newFeature: 'New',
            myClasses: 'My Classes',
            classPacks: 'Class Packs',
            students: 'Students',
            settings: 'Settings',
            instructorTrainingsText: 'Instructor Trainings',
            referFriendText: 'Refer A Friend',
            leadershipOpportunitiesText: 'Leadership Opportunities',
            teachingPlusPromotionText: 'Teaching + Promotion',
            trainingsPlusEducationText: 'Trainings + Education',
            earningsText: 'Earnings + Support',
            communityText: 'Community + Support',
            communityChampionsText: 'SYNC Community Champions',
            noomCoachingText: 'Noom Coaching',
            statusCardText: 'Membership Status Card',
        })
    },
    canAccessEducationSpecialistDocuments: { type: String, default: '' },
    canAccessEducationSpecialistMenu: { type: String, default: '' },
    canAccessMemberMenu: { type: String, default: '' },
    canAccessDcd: { type: String, default: '' },
    canManageGymProfile: { type: String, default: '' },
    canManageTrainingLocations: { type: String, default: '' },
    canRequestTrainingMaterials: { type: String, default: '' },
    canRequestTrainings: { type: String, default: '' },
    currentUserLocale: { type: String, default: '' },
    gaLabel: { type: String, default: '' },
    hasPendingFirstTraining: { type: String, default: '' },
    isDgfPublicEnabled: { type: String, default: '' },
    isSyncgoAppLinksEnabled: { type: String, default: '' },
    isClassbuzzAppLinksEnabled: { type: String, default: '' },
    hasTechnicalSessionLink: { type: String, default: '' },
    isGuestUser: { type: String, default: '' },
    isMembershipCirclInstructorNavigationEnabled: { type: String, default: '' },
    isMembershipCirclInstructorNavigationNewEnabled: { type: String, default: '' },
    isMembershipTypeZumba: { type: String, default: '' },
    isTippingLandingLinkLeftMenuEnabled: { type: String, default: '' },
    isSstudPublicSyncStudioLinkLeftMenuEnabled: { type: String, default: '' },
    manageClassesAction: { type: String, default: '' },
    manageClassesActive: { type: String, default: '' },
    manageClassesText: { type: String, default: '' },
    memberProfileEnabled: { type: String, default: '' },
    userHasBenefitCreateOnlineClass: { type: String, default: '' },
    userHasBenefitInstructorProfile: { type: String, default: '' },
    userHasBenefitStrongForumAccess: { type: String, default: '' },
    userHasBenefitZinJammerSessions: { type: String, default: '' },
    userHasBenefitMemberPortal: {type: String, default: ''},
    userHasNoomCertificationAccess: {type: String, default: ''},
    userHasNoomBenefit: {type: String, default: ''},
    userHasBenefitMembershipStatusCard: { type: String, default: '' },
    isAuthenticated: { type: String, default: '' },
    isOnHold: { type: String, default: ''},
    onHoldEnd: { type: String, default: ''},
    zinProfileAccountLink: { type: String, default: ''},
    isZjRefactorDashboardStrongEnabled: { type: String, default: ''},
    profileUrlParams: { type: String, default: ''},
    isStrongResultsMenuEnabled: { type: String, default: ''},
    onlineClassBaseUrl: { type: String, default: ''},
    requestQueryCat: { type: String, default: ''},
    isZjRefactorNavigationStrongEnabled: { type: String, default: ''},
    isStrongVirtualHeaderMenuEnabled: { type: String, default: ''},
    hasPreMembershipLinksOnly: { type: String, default: ''},
    navigationShopUrl: { type: String, default: ''},
    isStrongShopSubnavEnabled: { type: String, default: ''},
    shouldShowJoinLink: { type: String, default: ''},
    membershipTypeSlug: { type: String, default: ''},
    classManagementEnabled: { type: Boolean, default: false },
    classLocatorEnabled: {type: String, default: ''},
    isCirclUrl: {type: String, default: ''},
    isNoomCertificationEnabled: { type: String, default: '' },
    isBenefitMembershipStatusCardEnabled: { type: String, default: '' },
    restrictedReferFriend: {type: Boolean, default: false},
    restrictedInstructorTraining: {type: Boolean, default: false},
    restrictedLeadershipOpportunities: {type: Boolean, default: false},
    newReferralProgram: {type: Boolean, default: false},
    userHasDelinquentMembership: {type: String, default: ''},
    isPrePaymentAccessEnabled: {type: String, default: ''},
    showShareAndEarn: {type: Boolean, default: false},
    isJobBoardFormEnabled: {type: String, default: ''},
    isVanillaCommunityDisabled: { type: String, default: '' },
    hideJapaneseMenu: {type: Boolean, default: false},
    isGymPostAJobEnabled: {type: Boolean, default: false},
})

const showNoom = computed(() => props.isNoomCertificationEnabled && props.userHasNoomCertificationAccess && !props.hideJapaneseMenu)

const openTools = computed((): boolean => {
    const currentUrl = window.location.pathname
    return props.classManagementEnabled && (
        currentUrl.startsWith('/' + props.currentUserLocale + '/dashboard')
    )
})

const openClassManagement = computed((): boolean => {
    const currentUrl = window.location.pathname
    return props.classManagementEnabled && (
        currentUrl.startsWith('/' + props.currentUserLocale + '/member/class_management') ||
        currentUrl.startsWith('/' + props.currentUserLocale + '/member/instructor_page/') ||
        currentUrl.startsWith('/' + props.currentUserLocale + '/member/packages')
    )
})

const openNestedSubmenu = computed((): boolean => {
    return props.active?.['Teach'] || props.active?.['Learn'] || props.active?.['Earn'] || props.active?.['Connect']
})

const isKeyEmpty = (key) => Object.keys(key ?? {}).length == 0

const studentDashboardText = computed(() => props.userHasBenefitMemberPortal ? props.t.activityAsAStudentText : props.t.activityText)

const communityLink = computed(() => props.isVanillaCommunityDisabled ? 'https://www.zumba.community/STRONG_Nation_Community' : 'https://strong.zumba.community')
</script>

<style lang="css" scoped>
.new-feature-item {
    flex: 1;
}
.new-feature-tag-wrap  {
    flex: .6;
}
.new-feature-tag {
    margin-left: 1rem;
}
:deep(.new-feature-tag.tag) {
    display: inline;
}
</style>
