<template>
    <a
        class="menu-button icon-hamburger-svg"
        :class="{ hidden: hideButtonOnCheckoutPages() }"
        href="#"
        @click.prevent="handleClick"
    >
        {{ props.navigationText }}
    </a>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';

const handleClick = () => {
    const offCanvasWrapElement = document.getElementsByClassName('off-canvas-wrap')[0].classList
    offCanvasWrapElement.contains('move-right')
        ? offCanvasWrapElement.remove('move-right')
        : offCanvasWrapElement.add('move-right')
}

const hideButtonOnCheckoutPages = () => {
    const regex = /training\/[^/]+\/registration/;
    return window.location.href.includes('training/register') || regex.test(window.location.href)
}

onMounted(() => {
    document.addEventListener(('exit-canvas'), () => {
        handleClick()
    });
})

const props = defineProps({
    navigationText: {
        type: String,
        default: 'Navigation'
    },
})
</script>

<style scoped>
	.menu-button {
		left: 1em;
		position: absolute;
		z-index: 1;
		top: .375em;
		text-indent: 9999px;
		background-size: 923px 86px;
		background-position: -252px 0;
		width: 35px;
		height: 30px;
	}

    .menu-button.hidden {
        display: none;
    }

	.theme-strong .menu-button {
		background: var(--zumba-white);
		width: 2.3em;
		height: .3em;
		box-shadow: 0 .7em 0 0 var(--zumba-white), 0 1.4em 0 0 var(--zumba-white);
	}

	.theme-strong .menu-button::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 1.7em;
		top: 0;
		left: 0;
	}

	@media only screen and (min-width: 48em) {
		.menu-button {
			left: 2.125em;
			top: .6875em;
		}
	}

	@media only screen and (min-width: 64em) {
		.menu-button {
			display: none;
		}
	}
</style>
