<template>
    <header
        class="global-header"
        :class="{
            'sticky': state.isSticky,
            'no-clickable': hideMobileMenu
        }"
    >
        <slot
            v-if="props.isCmsShopBrandingZwEnabled"
            name="strongheaderlogo"
        />
        <a
            v-else
            :href="'/' + props.localizationUser"
            title="Strong Nation&reg;"
            class="gh-logo-strong-nation"
            data-ga-category="Global Logo"
            data-ga-action="Click"
            :data-ga-label="props.gaLabel"
            data-ga4-event-name="global_logo"
            data-ga4-action="Click"
            :data-ga4-details="props.gaLabel"
        />
        <div class="gh-menu-nav">
            <MenuButton
                v-if="!props.hideMobileMenu"
                :navigation-text="props.t.navigationText"
            />
            <ul
                class="gh-main-nav"
                :class="{ 'hide-nav-ele': hideNavigationElements }"
            >
                <template v-if="props.isStrongResultsMenuEnabled">
                    <!-- ABOUT -->
                    <li class="gh-main-nav-item">
                        <a
                            class="gh-main-nav-link"
                            :href="'/' + props.currentUserLocale + '/party'"
                            data-ga-category="Navigation Menu"
                            data-ga-action="About"
                            :data-ga-label="props.gaLabel"
                            data-ga4-event-name="navigation_menu"
                            data-ga4-action="About"
                            :data-ga4-details="props.gaLabel"
                        >
                            {{ props.t.aboutText }}
                            <ul
                                id="nav-trainings"
                                class="text-left dropdown-mtv dd-right"
                            >
                                <li>
                                    <a
                                        :href="'/' + props.currentUserLocale + '/party'"
                                        data-ga-category="Navigation Menu"
                                        data-ga-action="About_About the Class"
                                        :data-ga-label="props.gaLabel"
                                        data-ga4-event-name="navigation_menu"
                                        data-ga4-action="About_About the Class"
                                        :data-ga4-details="props.gaLabel"
                                    >
                                        {{ props.t.aboutTheClassText }}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        :href="'/' + props.currentUserLocale + '/about'"
                                        data-ga-category="Navigation Menu"
                                        data-ga-action="About_Our Story"
                                        :data-ga-label="props.gaLabel"
                                        data-ga4-event-name="navigation_menu"
                                        data-ga4-action="About_Our Story"
                                        :data-ga4-details="props.gaLabel"
                                    >
                                        {{ props.t.ourStoryText }}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        :href="'/' + props.currentUserLocale + '/member-benefits'"
                                        data-ga-category="Navigation Menu"
                                        data-ga-action="About_Member Benefits"
                                        :data-ga-label="props.gaLabel"
                                        data-ga4-event-name="navigation_menu"
                                        data-ga4-action="About_Member Benefits"
                                        :data-ga4-details="props.gaLabel"
                                    >
                                        {{ props.t.memberBenefitsText }}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        :href="'/' + props.currentUserLocale + '/results'"
                                        data-ga-category="Navigation Menu"
                                        data-ga-action="About_Results"
                                        :data-ga-label="props.gaLabel"
                                        data-ga4-event-name="navigation_menu"
                                        data-ga4-action="About_Results"
                                        :data-ga4-details="props.gaLabel"
                                    >
                                        {{ props.t.resultsText }}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        :href="'/' + props.currentUserLocale + '/clubs'"
                                        data-ga-category="Navigation Menu"
                                        data-ga-action="About_For Gyms"
                                        :data-ga-label="props.gaLabel"
                                        data-ga4-event-name="navigation_menu"
                                        data-ga4-action="About_For Gyms"
                                        :data-ga4-details="props.gaLabel"
                                    >
                                        {{ props.t.forGymsText }}
                                    </a>
                                </li>
                                <li v-if="props.isJobBoardFormEnabled">
                                    <a
                                        :href="'/' + props.currentUserLocale + '/clubs#job-form'"
                                        data-ga-category="Navigation Menu"
                                        data-ga-action="About_Hire an Instructor"
                                        :data-ga-label="props.gaLabel"
                                        data-ga4-event-name="navigation_menu"
                                        data-ga4-action="About_Hire an Instructor"
                                        :data-ga4-details="props.gaLabel"
                                    >
                                        {{ props.t.postAJobText }}
                                    </a>
                                </li>
                            </ul>
                        </a>
                    </li>
                </template>

                <!-- CLASSES -->
                <li class="gh-main-nav-item">
                    <a
                        class="gh-main-nav-link"
                        :href="'/' + props.localizationUser +'/party'"
                        :title="props.t.classesTitleCaseText"
                        data-ga-category="Navigation Menu"
                        data-ga-action="Classes"
                        :data-ga-label="props.gaLabel"
                        data-ga4-event-name="navigation_menu"
                        data-ga4-action="Classes"
                        :data-ga4-details="props.gaLabel"
                    >
                        {{ props.t.classesTitleCaseText }}
                    </a>

                    <ul
                        id="nav-classes"
                        class="text-left dropdown-mtv dd-right"
                    >
                        <li>
                            <a
                                :href="'/' + props.currentUserLocale + '/party'"
                                data-ga-category="Navigation Menu"
                                data-ga-action="About_About our Classes"
                                :data-ga-label="props.gaLabel"
                                data-ga4-event-name="navigation_menu"
                                data-ga4-action="About_About our Classes"
                                :data-ga4-details="props.gaLabel"
                            >
                                {{ props.t.aboutTheClassText }}
                            </a>
                        </li>

                        <li v-if="props.classLocatorEnabled">
                            <a
                                :href="'/' + props.currentUserLocale + '/class_search' + (props.isCirclUrl ? '?class-type=circl-mobility' : '')"
                                data-ga-category="Navigation Menu"
                                data-ga-action="About_Find a Class"
                                :data-ga-label="props.gaLabel"
                                data-ga4-event-name="navigation_menu"
                                data-ga4-action="About_Find a Class"
                                :data-ga4-details="props.gaLabel"
                            >
                                {{ props.t.findAClassText }}
                            </a>
                        </li>
                        <template v-else>
                            <li>
                                <a
                                    :href="'/' + props.currentUserLocale + '/party/nearMe?date=' + todayISO()"
                                    data-ga-category="Navigation Menu"
                                    data-ga-action="About_Find a Class"
                                    :data-ga-label="props.gaLabel"
                                    data-ga4-event-name="navigation_menu"
                                    data-ga4-action="About_Find a Class"
                                    :data-ga4-details="props.gaLabel"
                                >
                                    {{ props.t.findAnInPersonClassText }}
                                </a>
                            </li>
                            <li>
                                <a
                                    :href="props.onlineClassBaseUrl + '/?locale=' + props.currentUserLocale.replace('-', '_') + '&classType=STRONG+Nation'"
                                    data-ga-category="Navigation Menu"
                                    data-ga-action="Classes_Find a Virtual Class"
                                    :data-ga-label="props.gaLabel"
                                    data-ga4-event-name="navigation_menu"
                                    data-ga4-action="Classes_Find a Virtual Class"
                                    :data-ga4-details="props.gaLabel"
                                >
                                    {{ props.t.findAVirtualClassText }}
                                </a>
                            </li>
                        </template>

                        <li>
                            <a
                                :href="'/' + props.currentUserLocale + '/instructor'"
                                data-ga-category="Navigation Menu"
                                data-ga-action="About_Find an Instructor"
                                :data-ga-label="props.gaLabel"
                                data-ga4-event-name="navigation_menu"
                                data-ga4-action="About_Find an Instructor"
                                :data-ga4-details="props.gaLabel"
                            >
                                {{ props.t.findAnInstructorText }}
                            </a>
                        </li>
                        <li>
                            <a
                                :href="'/' + props.currentUserLocale + '/become-an-instructor'"
                                data-ga-category="Navigation Menu"
                                data-ga-action="About_Become an Instructor"
                                :data-ga-label="props.gaLabel"
                                data-ga4-event-name="navigation_menu"
                                data-ga4-action="About_Become an Instructor"
                                :data-ga4-details="props.gaLabel"
                            >
                                {{ props.t.becomeAnInstructorText }}
                            </a>
                        </li>
                    </ul>
                </li>

                <!-- INSTRUCTOR TRAININGS -->
                <li class="gh-main-nav-item">
                    <a
                        class="gh-main-nav-link"
                        :href="props.instructorTrainingsLink"
                        data-ga-category="Navigation Menu"
                        data-ga-action="Instructor Trainings"
                        :data-ga-label="props.gaLabel"
                        data-ga4-event-name="navigation_menu"
                        data-ga4-action="Instructor Trainings"
                        :data-ga4-details="props.gaLabel"
                    >
                        {{ props.t.instructorTrainingsText }}
                    </a>
                    <ul
                        id="nav-trainings"
                        class="text-left dropdown-mtv dd-right"
                    >
                        <li>
                            <a
                                :href="props.instructorTrainingsLink"
                                data-ga-category="Navigation Menu"
                                data-ga-action="Instructor Trainings_Become an Instructor"
                                :data-ga-label="props.gaLabel"
                                data-ga4-event-name="navigation_menu"
                                data-ga4-action="Instructor Trainings_Become an Instructor"
                                :data-ga4-details="props.gaLabel"
                            >
                                {{ props.t.becomeAnInstructorText }}
                            </a>
                        </li>
                        <li v-if="props.isStrongVirtualHeaderMenuEnabled && !props.isTrainingStrongRegistrationFlowEnabled">
                            <a
                                :href="'/' + props.currentUserLocale + '/become-an-instructor-virtual'"
                                data-ga-category="Navigation Menu"
                                data-ga-action="Instructor Trainings_View Virtual Trainings"
                                :data-ga-label="props.gaLabel"
                                data-ga4-event-name="navigation_menu"
                                data-ga4-action="Instructor Trainings_View Virtual Trainings"
                                :data-ga4-details="props.gaLabel"
                            >
                                {{ props.t.virtualTrainingsText }}
                            </a>
                        </li>
                        <li>
                            <a
                                v-if="props.isStrongVirtualHeaderMenuEnabled"
                                :href="'/' + props.currentUserLocale + '/training/nearby'"
                                data-ga-category="Navigation Menu"
                                data-ga-action="Instructor Trainings_View Find_A_Training Trainings"
                                :data-ga-label="props.gaLabel"
                                data-ga4-event-name="navigation_menu"
                                data-ga4-action="Instructor Trainings_View Find_A_Training Trainings"
                                :data-ga4-details="props.gaLabel"
                            >
                                {{ props.t.findATrainingText }}
                            </a>
                            <a
                                v-else
                                :href="'/' + props.currentUserLocale + '/training/nearby'"
                                data-ga-category="Navigation Menu"
                                data-ga-action="Instructor Trainings_View All Trainings"
                                :data-ga-label="props.gaLabel"
                                data-ga4-event-name="navigation_menu"
                                data-ga4-action="Shop"
                                :data-ga4-details="props.gaLabel"
                            >
                                {{ props.t.viewAllTrainingsText }}
                            </a>
                        </li>
                        <li v-if="props.isZjRefactorNavigationEnabled && props.isAuthenticated">
                            <a
                                :href="'/' + props.currentUserLocale + '/sync_labs'"
                                data-ga-category="Navigation Menu"
                                data-ga-action="Instructors_SYNC Labs"
                                :data-ga-label="props.gaLabel"
                                data-ga4-event-name="navigation_menu"
                                data-ga4-action="Instructor Trainings_View All Trainings"
                                :data-ga4-details="props.gaLabel"
                            >
                                {{ props.t.syncLabsText }}
                            </a>
                        </li>
                    </ul>
                </li>

                <!-- SHOP -->
                <li class="gh-main-nav-item">
                    <a
                        class="gh-main-nav-link"
                        :href="props.smtPublicCeaseStrongShopRedirection ? props.shopUrl : props.shopUrlWithRedirection"
                        :title="props.t.shopText"
                        data-ga-category="Navigation Menu"
                        data-ga-action="Shop"
                        :data-ga-label="props.gaLabel"
                        data-ga4-event-name="navigation_menu"
                        data-ga4-action="Shop"
                        :data-ga4-details="props.gaLabel"
                        target="_blank"
                        rel="noopener"
                    >
                        {{ props.t.shopText }}
                    </a>
                </li>
            </ul>
        </div>
    </header>
</template>

<script setup lang="ts">
import { reactive, onMounted, computed, PropType } from 'vue';
import MenuButton from '../nav/header/hamburger/Zumba/MenuButton.vue';
import { todayISO } from '@ts/Util/datetime';
import { HeaderFooterStyle } from "@ts/Layout/header-footer";

const props = defineProps({
    t: {
        type: Object,
        default: () =>({
            aboutText: 'About',
            aboutTheClassText: 'About the Class',
            becomeAnInstructorText: 'Become an Instructor',
            classesTitleCaseText: 'Classes',
            findAClassText: 'Find a Class',
            findATrainingText: 'Find A Training',
            findAVirtualClassText: 'Find a Virtual Class',
            findAnInPersonClassText: 'Find An In-Person Class',
            findAnInstructorText: 'Find an Instructor',
            forGymsText: 'For Gyms',
            instructorTrainingsText: 'Instructor Trainings',
            memberBenefitsText: 'Member Benefits',
            navigationText: 'Navigation',
            ourStoryText: 'Our Story',
            resultsText: 'Results',
            shopText: 'Shop Clothing',
            syncLabsText: 'SYNC Labs',
            viewAllTrainingsText: 'View All Trainings',
            virtualTrainingsText: 'Virtual Trainings',
            hireAnInstructorText: 'Hire an Instructor',
            postAJobText: 'Post A Job',
        })
    },
    currentUserLocale: {type: String, default: ''},
    gaLabel: {type: String, default: ''},
    headerFooterStyle: {type: String as PropType<HeaderFooterStyle>, default: 'full'},
    instructorTrainingsLink: {type: String, default: ''},
    isCmsShopBrandingZwEnabled: {type: String, default: ''},
    isStrongResultsMenuEnabled: {type: String, default: ''},
    isStrongVirtualHeaderMenuEnabled: {type: String, default: ''},
    isZjRefactorNavigationEnabled: {type: String, default: ''},
    localizationUser: {type: String, default: ''},
    onlineClassBaseUrl: {type: String, default: ''},
    smtPublicCeaseStrongShopRedirection: {type: String, default: ''},
    isAuthenticated: {type: String, default: ''},
    shopUrl: {type: String, default: ''},
    shopUrlWithRedirection: {type: String, default: ''},
    isTrainingStrongRegistrationFlowEnabled: {type: String, default: ''},
    classLocatorEnabled: {type: String, default: ''},
    isCirclUrl: {type: String, default: ''},
    isJobBoardFormEnabled: {type: String, default: ''},
    hideMobileMenu: {type: Boolean, default: false},
    isGymPostAJobEnabled: {type: Boolean, default: false}
})

const hideNavigationElements = computed((): boolean => {
    return props.headerFooterStyle === 'minimal';
})

const state = reactive({
    isSticky: false
})

onMounted(() => {
    addStickyObserver();
})

const addStickyObserver = () => {
    const headerElement = document.getElementById('header-toggle-component');
    const observerOptions = { threshold: 0.1 };
    const toggleIsSticky = entries => state.isSticky = entries[0].intersectionRatio < 0.1;
    const observer = new IntersectionObserver(toggleIsSticky, observerOptions);
    if (!headerElement) return;
    observer.observe(headerElement);
}

</script>

<style scoped>
a {
    text-decoration: none;
}
.gh-main-nav-link {
    font-family: 'Acherus-Bold', Helvetica, Arial, sans-serif;
}
.global-header {
    float: left;
    width: 100%;
    background: #272a38;
    position: relative;
    z-index: 100;
    box-sizing: border-box;
}
.active-modal .global-header {
    position: relative;
    z-index: 999;
}
.lte9 .sticky {
    top: 0;
}
.hide-sticky {
    display: none;
}

.gh-menu-nav {
    float: left;
    position: relative;
}
.gh-menu-cart {
    float: right;
    position: relative;
}
.strong-logos-sprite_strong-s-color, .gh-logo, .gh-logo-strong-nation, .gh-logo-strong-id {
    background: url("~@icons/strong-logos-sprite-042020.svg?external") no-repeat;
    background-size: 255px 1149px;
}
.strong-logos-sprite_strong-s-color, .gh-logo, .gh-logo-strong-nation, .gh-logo-strong-id {
    background-position: 0 0;
    width: 58px;
    height: 104px;
}
.gh-logo, .gh-logo-strong-nation, .gh-logo-strong-id {
    display: block;
    position: absolute;
    left: 50%;
    top: -0.8em;
    transform: translateX(-50%) scale(0.5);
}
.gh-logo-strong-id {
    background: url('~@icons/strong-id-logo.png?external') no-repeat;
    top: 0;
    width: 164px;
}
.gh-nav-trigger {
    left: 1em;
    position: absolute;
    z-index: 1;
    top: 0.375em;
    text-indent: -9999px;
    background: #fff;
    width: 2.3em;
    height: 0.3em;
    box-shadow: 0 0.7em 0 0 #fff, 0 1.4em 0 0 #fff;
}
.gh-nav-trigger:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1.7em;
    top: 0;
    left: 0;
}
.lte9 .gh-nav-trigger {
    display: none;
}
.lang-ko-KR .gh-nav-trigger {
    text-indent: -9999px;
}
.gh-secondary-nav {
    list-style: none;
    display: block;
}
.gh-secondary-nav > li, .gh-main-nav > li {
    float: left;
}
.gh-secondary-nav {
    margin-top: 0.75em;
}
.gh-secondary-nav > li {
    position: relative;
}
.gh-main-nav {
    list-style: none;
    display: none;
}
.gh-main-nav-item {
    margin-left: 1.5em;
    position: relative;
}
.dropdown-mtv li a {
    font-family: 'Acherus-Bold', Helvetica, Arial, sans-serif;
}
.dropdown-mtv {
    padding: 0.5em 0;
    border: 0;
    background: #fff;
    z-index: 9999;
    margin-top: 1em;
    text-align: left;
    width: 14em;
    left: 0;
    top: 1.75em !important;
    display: none;
    position: absolute;
    list-style: none;
    margin-left: 0;
    max-height: none;
    height: auto;
    margin-top: 2px;
}
.dropdown-mtv:before {
    content: '';
    top: -1em;
    border: solid 8px;
    border-color: transparent transparent #fff transparent;
    position: absolute;
    left: 1em;
}
.dropdown-mtv:after {
    content: '';
    position: absolute;
    left: 0;
    top: -1em;
    padding: 0.6em 0;
    display: block;
    width: 100%;
}
.dropdown-mtv:hover {
    display: block;
}
.dropdown-mtv.dd-right {
    left: initial !important;
    right: 0;
}
.dropdown-mtv.dd-right:before {
    left: initial;
    right: 1em;
}
.dropdown-mtv li a {
    padding: 1em 1.25em;
    font-size: 0.8125rem;
    color: #1d1d24;
    text-transform: uppercase;
    line-height: 1em;
    display: block;
}
.dropdown-mtv li a:hover {
    background: #95959a;
}
.gh-main-nav-item .dropdown-mtv {
    background: #2f3337;
}
.gh-main-nav-item .dropdown-mtv a {
    color: #fff;
}
.gh-main-nav-item .dropdown-mtv:before {
    border-color: transparent transparent #2f3337 transparent;
}
.gh-main-nav-item:hover {
    padding-bottom: 1.2em;
}
.gh-main-nav-item:hover .dropdown-mtv {
    display: block;
}
.gh-main-nav-item:hover .dropdown-mtv a:hover {
    background: #1d1d24;
}
.lte9 .gh-main-nav-item {
    float: left;
    margin-left: 30px;
}
.gh-main-nav-item {
    margin-left: 2.2em;
}
.gh-main-nav-link {
    display: block;
    font-size: 0.875rem;
    text-transform: uppercase;
    color: #fff;
}
.gh-main-nav-link:hover {
    color: #fff;
}
.gh-profile-nav {
    display: none;
}
.gh-lang {
    display: none;
    position: relative;
    line-height: 1em;
    padding-right: 0.8em;
}
.no-clickable {
    pointer-events: none
}
@media only screen and (max-width: 47.9375em) {
    .hide-sticky {
        display: block;
    }
    .gh-logo-strong-id {
        transform: translateX(-50%) scale(0.7);
        top: 0.4em;
    }
}
@media only screen and (min-width:48em) {
    .global-header {
        padding: 1em 0 0;
        height: 5.5em;
    }
    .gh-logo, .gh-logo-strong-nation, .gh-logo-strong-id {
        -webkit-transform: translateX(-50%) scale(0.6);
        -moz-transform: translateX(-50%) scale(0.6);
        -ms-transform: translateX(-50%) scale(0.6);
        -o-transform: translateX(-50%) scale(0.6);
        transform: translateX(-50%) scale(0.6);
    }
    .gh-nav-trigger {
        left: 2.125em;
        top: 1em;
    }
    .gh-secondary-nav {
        margin: 1em 0.5em 0 0;
    }
}
@media only screen and (min-width:64em) {
    .global-header {
        z-index: 1009;
        padding: 1em 1.5em 0;
        text-align: right;
    }
    .sticky {
        position: fixed;
        top: 0;
    }
    .gh-menu-nav {
        float: right;
    }
    .gh-logo, .gh-logo-strong-nation, .gh-logo-strong-id {
        display: block;
        float: left;
        margin: 0;
        position: relative;
        top: -0.26em;
        -webkit-transform: translateX(0) scale(1);
        -moz-transform: translateX(0) scale(1);
        -ms-transform: translateX(0) scale(1);
        -o-transform: translateX(0) scale(1);
        transform: translateX(0) scale(1);
        left: 0;
        width: initial;
        height: initial;
        background-position: 0 100%;
        width: 255px;
        height: 65px;
    }
    .gh-logo-strong-nation {
        -webkit-transform: translateX(0) scale(0.8);
        -moz-transform: translateX(0) scale(0.8);
        -ms-transform: translateX(0) scale(0.8);
        -o-transform: translateX(0) scale(0.8);
        transform: translateX(0) scale(0.8);
        background-position: 0 30.3751143641%;
        width: 181px;
        height: 56px;
        top: 0;
    }
    .gh-nav-trigger {
        display: none;
    }
    .gh-secondary-nav {
        margin: 1em 0 0 0;
        float: right;
    }
    .gh-secondary-nav > li {
        margin-left: 2.2em;
    }
    .gh-main-nav {
        margin: 1.125em 0 0 0;
        display: block;
        float: left;
    }
    .gh-profile-nav {
        display: block;
        float: right;
        margin: 0.125em 0 0;
    }
    .gh-lang {
       display: inherit !important;
    }
}
</style>
