<template>
    <div>
        <div
            v-if="props.isAuthenticated"
            class="row ocn-profile"
        >
            <span class="left ocn-profile-name">
                {{ `${props.t.helloText} ${props.profileName}` }}
            </span>
            <a
                :href="'/' + props.currentUserLocale + '/account'"
                class="right ocn-settings"
                data-ga-category="Navigation Menu"
                data-ga-action="Account Gear"
                :data-ga-label="props.gaLabel"
                data-ga4-event-name="navigation_menu"
                data-ga4-action="Account Gear"
                :data-ga4-details="props.gaLabel"
            >
                {{ props.t.myAccountText }}
            </a>
        </div>
        <div
            v-else
        >
            <div
                v-if="props.isAclHeaderEnabled"
            >
                <a
                    :href="props.loginUrl"
                    class="ocn-user-acl"
                >
                    {{ props.t.logInText }}
                </a>
                <a
                    :href="props.signUpUrl"
                    class="ocn-user-acl"
                >
                    {{ props.t.signUpText }}
                </a>
            </div>
            <a
                v-else
                :href="props.loginUrl"
                class="ocn-user-session"
            >
                {{ props.t.signInText }}
            </a>
        </div>

        <ul
            ref="accordionRef"
            class="accordion no-bullet ocn-list"
        >
            <slot name="usermenu" />
            <slot name="support" />
            <slot
                v-if="props.canAccessSixWeeksProgramProfile"
                name="6week"
            />
            <slot name="strong" />
            <li
                v-if="props.isZumbiniLogoEnabled"
                class="ocn-list-item"
            >
                <a
                    href="https://www.zumbini.com/"
                    class="ocn-list-link"
                    rel="noopener"
                    target="_blank"
                >
                    {{ props.t.zumbiniText }}
                </a>
            </li>

            <li class="ocn-list-item">
                <a
                    href=""
                    class="more-below ocn-list-link"
                >
                    {{ props.t.languageText }}
                    <span class="ocn-list-lang"> {{ props.language }} </span>
                </a>
                <ul
                    class="ocn-subnav"
                    :style="props.active ? 'display: block;' : ''"
                >
                    <li class="ocn-list-item">
                        <a
                            :href="'/en-US' + props.uri"
                            class="ocn-list-link"
                            data-ga-category="Global Language Switch"
                            data-ga-action="English"
                            :data-ga-label="props.gaLabel"
                            data-ga4-event-name="global_language_switch"
                            data-ga4-action="English"
                            :data-ga4-details="props.gaLabel"
                        >
                            {{ props.t.englishText }}
                        </a>
                    </li>
                    <li class="ocn-list-item">
                        <a
                            :href="'/fr-FR' + props.uri"
                            class="ocn-list-link"
                            data-ga-category="Global Language Switch"
                            data-ga-action="French"
                            :data-ga-label="props.gaLabel"
                            data-ga4-event-name="global_language_switch"
                            data-ga4-action="French"
                            :data-ga4-details="props.gaLabel"
                        >
                            {{ props.t.frenchText }}
                        </a>
                    </li>
                    <li class="ocn-list-item">
                        <a
                            :href="'/it-IT' + props.uri"
                            class="ocn-list-link"
                            data-ga-category="Global Language Switch"
                            data-ga-action="Italian"
                            :data-ga-label="props.gaLabel"
                            data-ga4-event-name="global_language_switch"
                            data-ga4-action="Italian"
                            :data-ga4-details="props.gaLabel"
                        >
                            {{ props.t.italianText }}
                        </a>
                    </li>
                    <li class="ocn-list-item">
                        <a
                            :href="'/es-ES' + props.uri"
                            class="ocn-list-link"
                            data-ga-category="Global Language Switch"
                            data-ga-action="Spanish"
                            :data-ga-label="props.gaLabel"
                            data-ga4-event-name="global_language_switch"
                            data-ga4-action="Spanish"
                            :data-ga4-details="props.gaLabel"
                        >
                            {{ props.t.spanishText }}
                        </a>
                    </li>
                    <li class="ocn-list-item">
                        <a
                            :href="'/pt-BR' + props.uri"
                            class="ocn-list-link"
                            data-ga-category="Global Language Switch"
                            data-ga-action="Portuguese"
                            :data-ga-label="props.gaLabel"
                            data-ga4-event-name="global_language_switch"
                            data-ga4-action="Portuguese"
                            :data-ga4-details="props.gaLabel"
                        >
                            {{ props.t.portugueseText }}
                        </a>
                    </li>
                    <li class="ocn-list-item">
                        <a
                            :href="'/de-DE' + props.uri"
                            class="ocn-list-link"
                            data-ga-category="Global Language Switch"
                            data-ga-action="Deutsch"
                            :data-ga-label="props.gaLabel"
                            data-ga4-event-name="global_language_switch"
                            data-ga4-action="Deutsch"
                            :data-ga4-details="props.gaLabel"
                        >
                            {{ props.t.deutschText }}
                        </a>
                    </li>
                    <li class="ocn-list-item">
                        <a
                            :href="'/ko-KR' + props.uri"
                            class="ocn-list-link"
                            data-ga-category="Global Language Switch"
                            data-ga-action="Korean"
                            :data-ga-label="props.gaLabel"
                            data-ga4-event-name="global_language_switch"
                            data-ga4-action="Korean"
                            :data-ga4-details="props.gaLabel"
                        >
                            {{ props.t.koreanText }}
                        </a>
                    </li>
                    <li
                        v-if="props.isIndonesianLanguageIncluded"
                        class="ocn-list-item"
                    >
                        <a
                            :href="'/id-ID' + props.uri"
                            class="ocn-list-link"
                            data-ga-category="Global Language Switch"
                            data-ga-action="Indonesian"
                            :data-ga-label="props.gaLabel"
                            data-ga4-event-name="global_language_switch"
                            data-ga4-action="Indonesian"
                            :data-ga4-details="props.gaLabel"
                        >
                            {{ props.t.indonesianText }}
                        </a>
                    </li>
                    <li
                        v-if="props.isJapaneseEnabled"
                        class="ocn-list-item"
                    >
                        <a
                            :href="'/ja-JP' + props.uri"
                            class="ocn-list-link"
                            data-ga-category="Global Language Switch"
                            data-ga-action="Japanese"
                            :data-ga-label="props.gaLabel"
                            data-ga4-event-name="global_language_switch"
                            data-ga4-action="Japanese"
                            :data-ga4-details="props.gaLabel"
                        >
                            日本語
                        </a>
                    </li>
                </ul>
            </li>
            <li class="ocn-list-item">
                <a
                    :href="'/' + props.currentUserLocale + '/localization?redirect=' + encodeURIComponent(props.localeRedirect)"
                    class="ocn-list-link"
                    :class="{'js-active-item': props.active}"
                    title="Zumba"
                >
                    {{ props.t.countryText }}
                    <picture>
                        <source
                            :srcset="'data:image/webp;base64,' + props.iconBase64Encode "
                            type="image/webp"
                        >
                        <img
                            class="flag-only"
                            :src="'/img/blt/icons/country-flags/' + props.countryCode + '.png'"
                            :alt="props.countryCode.toUpperCase()"
                        >
                    </picture>
                </a>
            </li>
            <li
                v-if="props.isAuthenticated"
                class="ocn-list-item"
            >
                <a
                    :href="'/' + props.currentUserLocale + '/logout'"
                    class="ocn-list-link"
                >{{ props.t.signOutText }}</a>
            </li>
        </ul>
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';

const props = defineProps({
    t: {
        type: Object,
        default: () => ({
            helloText: 'Hello',
            myAccountText: 'My Account',
            signInText: 'Sign In',
            zumbiniText: 'Zumbini',
            languageText: 'Language',
            englishText: 'English',
            frenchText: 'French',
            italianText: 'Italian',
            spanishText: 'Spanish',
            portugueseText: 'Portuguese',
            deutschText: 'Deutsch',
            koreanText: 'Korean',
            indonesianText: 'Indonesian',
            countryText: 'Country',
            signOutText: 'Sign Out',
            logInText: 'Log In',
            signUpText: 'Sign Up',
            joinZinText: 'Join ZIN™',
            updatePaymentText: 'Update Payment',
        })
    },
    active: { type: String, default: ''},
    countryCode: { type: String, default: 'us'},
    currentUserLocale: { type: String, default: ''},
    gaLabel: { type: String, default: ''},
    iconBase64Encode: { type: String, default: ''},
    isIndonesianLanguageIncluded: { type: Boolean, default: false},
    isZumbiniLogoEnabled: { type: Boolean, default: false},
    language: { type: String, default: 'EN'},
    localeRedirect: { type: String, default: ''},
    loginUrl: { type: String, default: ''},
    signUpUrl: { type: String, default: ''},
    profileName: { type: String, default: ''},
    uri: { type: String, default: ''},
    isAuthenticated: { type: String, default: ''},
    isAclHeaderEnabled: {type: Boolean, default: false},
    isJapaneseEnabled: {type: Boolean, default: false},
    canAccessSixWeeksProgramProfile: {type: String, default: ''},
})

const accordionRef = ref(null);
const $ = (window as any).$;

var setActiveLink = function (e) {
    const el = $(e.currentTarget);
    const children = el.siblings('ul');
    const hasChildren = children.length;
    const isTopLevelLink = el.parent().parent().hasClass('ocn-list');


    const isActive = el.hasClass('js-active-item');

    // If the link has child elements, don't navigate to them
    if (hasChildren) {
        e.stopPropagation();
        e.preventDefault();
    }

    // If the link has child elements and isn't active, open the menu
    if (hasChildren && !isActive) {
        openMenu(children);
    }

    // If the link has child elements and is active, close menu and all submenus
    if (hasChildren && isActive) {
        closeMenu(children);
        openMenu(children.find('li:hidden'));
        closeMenu(children.find('ul'));
    }

    // If the link isn't top-level, hide other choices in previous level
    if (!isTopLevelLink) {
        hideInactiveMenus(children);
    }

    // Set the active or inactive state on link
    setLinkStatus();
};
const setActivePageState = function(link) {
    const el = $(link)
    if (el.hasClass('pre-selected-active')) {
        const children = el.siblings('ul');
        const hasChildren = children.length;
        const isTopLevelLink = el.parent().parent().hasClass('ocn-list');
        const isActive = el.hasClass('js-active-item');
        // If the link has child elements and isn't active, open the menu
        if (hasChildren && !isActive) {
            openMenu(children);
        }
        // If the link isn't top-level, hide other choices in previous level
        if (!isTopLevelLink) {
            hideInactiveMenus(children);
        }
    }
}

var openMenu = function (menu) {
    menu.siblings('a').addClass('js-active-item');
    menu.slideDown().addClass('js-active-menu');
};

var hideInactiveMenus = function (menu) {
    menu.parent().siblings('li').slideToggle();
};

var closeMenu = function (menu) {
    menu.siblings('a').removeClass('js-active-item');
    menu.slideUp().removeClass('js-active-menu');
};

var setLinkStatus = function () {
    $('.ocn-list a').removeClass('active-item').removeClass('inactive-item');
    $('.js-active-item:last').addClass('active-item');
    $('.js-active-item').not(':last').addClass('inactive-item');
};

onMounted(() => {
    if (!accordionRef.value) {
        return;
    }
    const links = accordionRef.value.querySelectorAll('a');
    links.forEach(link => {
        link.addEventListener('click', event => setActiveLink(event));
        setActivePageState(link);
    });
})
</script>
<style scoped>

.ocn-user-acl {
	display: block;
	position: relative;
	padding: .938em 1em .725em;
	color: var(--zumba-white);
	border-bottom: 1px solid var(--zumba-gray-700);
}
</style>
